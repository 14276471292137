import React from "react"
import TermsMobileUk from "../../../../components/body/pages/mobile/uk/termsUk"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"

const TermsUkPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/terms/"}
      title="Terms & Conditions | Kuda | The Money App for Africans"
    />
    <TermsMobileUk />
  </NoheaderFooter>
)

export default TermsUkPage
